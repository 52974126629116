import { Col, Divider, Row, Typography, Avatar, Timeline, Modal, Button, Form, Select, Input, message } from "antd";
import React, { useContext, useState } from "react";
import moment from "moment";
import { colorsLight } from "../../Styles/Themes";
import { PlusCircleOutlined } from "@ant-design/icons";
import { firestore } from "firebase";
import UserContext from "../../Context/UserContext";

const FulfilmentPanel = ({ data, programData }) => {
	const [modal, setModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const { userInfo } = useContext(UserContext);

	const toggleModal = () => setModal(!modal);

	const saveUpdate = async (val) => {
		setLoading(true);
		try {
			const obj = {
				message: val.message,
				type: val.type,
				created: moment().format(),
			};
			let current = programData.fulfilmentTimeline || [];
			current.push(obj);
			await firestore().collection("Fulfilment").doc(data.key).update({ status: val.type });
			await firestore().collection("Programs").doc(programData.key).update({ fulfilmentTimeline: current, status: val.type });
			setLoading(false);
			setModal(false);
			message.success("status added to program");
		} catch (err) {
			message.error("Could not update program");
			setLoading(false);
		}
	};

	return (
		<Col className="Card">
			<Row>
				<Col>
					<Avatar
						size={62}
						src={programData.displayPic}
						style={{
							backgroundColor: colorsLight("lightgrey"),
							boxShadow: "2px 2px 3px rgba(0,0,0,0.4)",
						}}
					/>
				</Col>
				<Col style={{ marginLeft: 12 }}>
					<Typography.Title style={{ margin: 0 }} level={3}>
						{data.name || "Loading"}
					</Typography.Title>
					<p style={styles.providerName}>{data.providerName || "Loading"}</p>
				</Col>
			</Row>
			<Divider style={{ margin: "6px 0px 0px 0px" }} />
			<Row style={{ paddingTop: 12 }} gutter={12}>
				<Col span={12}>
					<Row justify="space-between">
						<p className="Key">Member Name:</p>
						<p className="Value">{data.memberName || "Loading"}</p>
					</Row>
					<Row justify="space-between">
						<p className="Key">Email:</p>
						<a href={`mailto:${data.memberEmail}`} target="_blank" className="Value">
							{data.memberEmail || "Loading"}
						</a>
					</Row>
				</Col>
				<Col span={12}>
					<Row justify="space-between">
						<p className="Key">Created:</p>
						<p className="Value">{data.created ? moment(data.created).fromNow() : "Loading"}</p>
					</Row>
				</Col>
			</Row>
			<Divider style={{ margin: "6px 0px 6px 0px" }} />
			<Row>
				<Typography.Title level={5}>Fulfilment Process:</Typography.Title>
			</Row>
			<Row style={{ width: "100%", padding: "12px 24px 12px 24px" }}>
				<Timeline mode="left" style={{ width: "100%" }}>
					<Timeline.Item label={moment(data.created).format("ddd Do MMMM YY @ h:mm a")}>Purchased Offering</Timeline.Item>
					{programData.fulfilmentTimeline &&
						programData.fulfilmentTimeline.map((item, index) => (
							<Timeline.Item key={index} label={moment(item.created).format("ddd Do MMMM YY @ h:mm a")}>
								<p className="Key" style={{ margin: 0 }}>
									{item.type}
								</p>
								<p style={{ fontSize: 12, margin: 0, color: "grey" }}>{`Updated by ${userInfo.displayName}`}</p>
								{item.message}
							</Timeline.Item>
						))}
					<Timeline.Item
						dot={<PlusCircleOutlined onClick={toggleModal} style={{ color: "grey", cursor: "pointer" }} />}></Timeline.Item>
				</Timeline>
			</Row>
			<Modal
				visible={modal}
				onCancel={toggleModal}
				title="Add new fulfilment note"
				footer={[
					<Button onClick={toggleModal}>Cancel</Button>,
					<Button loading={loading} type="primary" htmlType="submit" form="updateForm">
						Update
					</Button>,
				]}>
				<Form onFinish={saveUpdate} id="updateForm" layout="vertical">
					<Form.Item rules={[{ required: true }]} name="type" label="Update Type">
						<Select placeholder="Please select update type">
							<Select.Option value="Processing">Processing</Select.Option>
							<Select.Option value="Confirming">Confirming</Select.Option>
							<Select.Option value="Completed">Completed</Select.Option>
							<Select.Option value="Booked">Booked</Select.Option>
							<Select.Option value="Delayed">Delayed</Select.Option>
							<Select.Option value="Cancelled">Cancelled</Select.Option>
						</Select>
					</Form.Item>
					<Form.Item rules={[{ required: true, message: "Please enter notes" }]} label="Notes" name="message">
						<Input.TextArea placeholder="Please enter update notes" />
					</Form.Item>
				</Form>
			</Modal>
		</Col>
	);
};

const styles = {
	providerName: {
		fontSize: 18,
		margin: 0,
	},
};

export default FulfilmentPanel;
